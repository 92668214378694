<template>
    <div class="wmp-login">
        <p class="title">授权登录</p>
        <p class="desc">时代飞鹰·智能AI助手</p>
        <img class="logo" :src="logoSrc">
        <el-button class="login-btn" @click="Login" type="success">微信登陆</el-button>
    </div>
</template>
<style lang="stylus" scoped>
.wmp-login{
    display: block;
    width:100%;
    position: absolute;
    box-sizing: border-box;
    padding :15px;
    top :30%;
    left: 50%;
    transform: translate(-50%,-50%)
    .title{
        font-size:18px;
        text-align: center;
    }
    .logo{
        width :30%;
        display: block;
        margin:20px auto;
    }
    .desc{
        font-size:16px;
        text-align: center;
    }
    .login-btn{
        display: block;
        width: 100%;
    }
}
</style>
<script>
import { WmpLogin } from "@/api/weixin.js";
export default {
    name:"wmp-login",
    data(){
        return {
            logoSrc:require("@/assets/imgs/logo-qrcode.jpeg") ,
            state:encodeURI(window.location.protocol+"//"+window.location.hostname)
        }
    },
    methods:{
        Login(){//开始登陆
            let history = this.$route
            WmpLogin(this.state)
        }
    },
    mounted(){
        let state = this.$route.query["state"]
        if(state){
            this.state = state
        }
        console.log(this.state)
        // console.log(this.$route.query["state"])

        //WmpLogin(encodeURI(window.location.href.split("?")[0]))
    }
        
    
}
</script>
