
export function WmpLogin(state) {//微信网页端登陆
    state = state || 'STATE'
    // let redirect_uri =encodeURI(process.env.VUE_APP_HOST_NAME + "/wxLoginCode") 
    let appid = process.env.VUE_APP_WMP_WEIXIN_APPID
    let redirect_uri = encodeURI(process.env.VUE_APP_LOGIN_REDIRECT_URI)
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?` +
        `appid=${appid}&` +
        `redirect_uri=${redirect_uri}&` +
        `response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`+
        "forcePopup=true"
    window.location.href = url
}
export function JsApiPay(data) {
    return new Promise((reslove, reject) => {
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            data,
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                   reslove(res)
                }else{
                    reject(res)
                }
            });
    })
}
